@import "~bootstrap/scss/bootstrap.scss";
@import "~tabulator-tables/dist/css/tabulator_bootstrap4.css";

* {
  box-sizing: border-box;
}

body {
  background-color: #444;
  color: #ffffff;
}



h1, h2, h3, p {
  text-align: center;  
}

h3 {
  font-size:20px;
	
}

.list-group-item {
	background-color: #444;
	border: 0;
	
}

.calcheader {
  height:30px;
  border-radius: 25px;
  background-color: #343a40;
}

.calcheader:hover{
	
	  cursor: pointer;
}

.odin {
  display: none;
}

input {
	
	max-width:250px;
}

select {
	
	max-width:250px;
}

.tabulator-header-filter input
{
 float:left;
 margin-bottom:5px;
}   
